import commonUtils from './common'
import { replaceHrefSpecChar } from '@shein/common-function'
import schttp from 'public/src/services/schttp'

const UtilCache = {
  scLinkMap: {
    // scId: 'www.shein.com/p-sc-scId'
  },
  get(module, key) {
    if (this[module]) {
      return this[module][key]
    } else {
      console.error('Module does not exist.')
    }
  },
  set(module, key, value) {
    if (this[module]) {
      this[module][key] = value
    } else {
      console.error('Module does not exist.')
    }
  }
}

/**
 * 计算倒计时
 */
function countDownTime({
  endTimestamp = 0
}) {
  const end = endTimestamp * 1000
  const now = (new Date()).getTime()
  const diffTime = end - now
  const diffDays = Math.floor(diffTime / 1000 / 60 / 60 / 24) // 相差天数
  const diffHours = Math.floor(diffTime / 1000 / 60 / 60 - diffDays * 24) // 相差小时
  const diffMinutes = Math.floor(diffTime / 1000 / 60 - diffDays * 24 * 60 - diffHours * 60) // 相差分钟
  const diffSeconds = Math.floor(diffTime / 1000 - diffDays * 24 * 60 * 60 - diffHours * 60 * 60 - diffMinutes * 60) // 相差秒数

  return {
    diffTime,
    diffDays,
    diffHours,
    diffMinutes,
    diffSeconds
  }
}

/**
 * 生成活动未达成跳转链接
 * @param {String} scId [选品id]
 */
function promotionLink({
  scId,
  mall_code,
  typeId,
  flash_type,
  flashType,
  url_prams = '',
  entry_from = '',
  src_module = '',
  src_identifier = '',
  src_tab_page_id = '',
}) {
  return new Promise((resolve) => {
    let isBrandFlash = (flashType || flash_type) == 6
    if (typeId == 10) {
      if (isBrandFlash) {
        // 当前没有品牌特卖落地页，只能跳到品牌频道首页
        let url = `/brand-zone.html?entry_from=${entry_from}&src_module=${src_module}&src_identifier=${src_identifier}&src_tab_page_id=${src_tab_page_id}`
        resolve(url)
      } else {
        let url = `/flash-sale.html?flash_type=${flash_type || flashType || 1}`
        if (mall_code) {
          url += `&mallCode=${mall_code}`
        }
        resolve(url)
      }
    } else if (typeId == 30) {
      let url = `/under-prices-products.html?${url_prams}`
      resolve(url)
    } else if (scId) {
      // 优先取缓存
      if (UtilCache.get('scLinkMap', scId)) {
        resolve(UtilCache.get('scLinkMap', scId))
      } else {
        schttp({
          url: '/api/productInfo/selectInfoByScId/get',
          params: {
            sc_id: scId
          }
        }).then( data => {
          if (data && data.info && data.info.scInfo && data.info.scInfo.length) {
            const {
              sc_url_id,
              select_name,
              select_type_name,
              sc_url
            } = data.info.scInfo[0]
            let url = ''
            if (sc_url) {
              url = `${gbCommonInfo.langPath}${sc_url}`
            } else if (sc_url_id && select_name && select_type_name) {
              url = `${gbCommonInfo.langPath}/${select_type_name}/${select_name}-sc-${sc_url_id}.html`
            }
            if (mall_code) {
              url += (url.indexOf('?') > -1 ? '&mallCode=' : '?mallCode=') + mall_code
            }
            UtilCache.set('scLinkMap', scId, url)
            resolve(replaceHrefSpecChar({ href: url }))
          } else {
            resolve('')
          }
        })
      }
    } else {
      resolve('')
    }
  })
}

/**
 * 未满足档次跳转
 * @param {Object} item [促销数据]
 */
async function jumpToAdd(item, params) {
  let url = await promotionLink(item)
  if (url) {
    if (params?.source) {
      url += (url.indexOf('?') > -1 ? '&productsource=' : '?productsource=') + params.source
    }
    if (params?.scence === 'paidVip') {
      location.href = url
    } else {
      const newWin = window?.open?.('', '_blank')
      if (!newWin) return
      newWin.location = url
    }
  }
}

/**
 * 促销活动标识处理
 * @param {Object} item [商品数据]
 * @param {Object} p [促销信息]
 */
function assignOptions({
  item,
  p
}) {
  const {
    original_discount = '', unit_discount = ''
  } = item
  let options = {}
  if (p.typeId == 3) {
    let isMoreDiscount = original_discount && unit_discount && Number(original_discount) !== 0 && Number(original_discount) != Number(unit_discount)
    options.isMoreDiscount = isMoreDiscount
  }
  if ([10, 11].includes(Number(p.typeId)) || p.typeId == 24 && p.ruleType == 'no_discount' && !Number(p.orderNum) && Number(p.singleNum)) {
    options.isLimitedItem = true
  }
  return options
}

export const PromotionUtils = {
  ...commonUtils,
  countDownTime,
  promotionLink,
  jumpToAdd,
  assignOptions
}
